import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputNumber, Select, DatePicker } from "antd";
import "./SiteManagement.css";
import { LoadingOutlined } from "@ant-design/icons";
import Loading from "../../components/Loading/Loading";
import moment from "moment";
import "moment/locale/th";
import { openNotification, getSearchParams } from "../../utils/function";
import { apiAMGetAdmin } from "../../services/apis/admin";
import { apiSGetSiteManagementGroups } from "../../services/apis/site";
import { apiARGetProvince } from "../../services/apis/area";
import SiteManagementMaps from "./SiteManagementMaps-v2";
import { ADD_SITE, UPDATE_SITE } from "../../resolvers/Mutation";
import { QueryGraphQL, GET_SITES, GET_ADMIN } from "../../resolvers/Query";
import { useMutation, useQuery } from "@apollo/client";
import { locationLatLng } from "../../redux/place";

const { Option } = Select;
const { RangePicker } = DatePicker;

const SiteManagementAddEditV2 = () => {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const { siteId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const sw = params.get("sw");

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [site, setSite] = useState(undefined);
  const [admins, setAdmins] = useState([]);
  const [branchGroups, setBranchGroups] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [locationName, setLocationName] = useState("");

  const [isAlertNameTH, setIsAlertNameTH] = useState(false);
  const [isAlertNameEN, setIsAlertNameEN] = useState(false);
  const [isAlertAdmin, setIsAlertAdmin] = useState(false);
  const [isAlertPayAmount, setIsAlertPayAmount] = useState(false);
  const [isAlertProvince, setIsAlertProvince] = useState(false);
  const [isAlertzone, setIsAlertzone] = useState(false);
  const [isAlerttier, setIsAlerttier] = useState(false);
  const [isAlertmap, setIsAlertmap] = useState(false);
  const [isAlertversion, setIsAlertversion] = useState(false);
  const datadminlist = QueryGraphQL(GET_ADMIN);
  const dataSiteList = QueryGraphQL(GET_SITES);
  const datadmin = datadminlist?.data?.getAdmins;
  const dataSites = dataSiteList?.data?.getSites;
  const [editSite, { data, error }] = useMutation(UPDATE_SITE);
  const [addSite, dataAdd] = useMutation(ADD_SITE);
  const { dataAdmin2 } = useQuery(GET_ADMIN);
  // const dataSiteItem = useSelector((state) => state.dataV2.dataSiteItem);
  const site_name = localStorage.getItem("site_name");
  console.log("SiteManagementAddEditV2-data->🙂", admins);
  console.log("SiteManagementAddEditV2-data->🙂", site);

  useEffect(() => {

    if (isLoading) {
      if (siteId) {
        if (error) {
          setIsLoading(false);
          openNotification("error", "Failed !", `Failed to edit site.`);
        }
        if (data?.updateSite) {
          setIsLoading(false);
          openNotification("success", "Success !", `Edit site successful.`);
          setTimeout(
            () =>
              history.push(
                `/site-management/list/v2`
              ),
            1000
          );
        }
      } else {
        if (dataAdd?.error) {
          setIsLoading(false);
          openNotification("error", "Failed !", `Failed to add new site.`);
        }
        if (dataAdd?.data?.addSite) {
          setIsLoading(false);
          openNotification("success", "Success !", `Add new site successful.`);
          setTimeout(
            () =>
            (window.location.href = `/machine/list/v2${getSearchParams(
              ["p", "ttl", "sw"],
              [p, ttl, sw]
            )}`),
            1000
          );
        }
      }
    }
  }, [error, data, dataAdd]);

  useEffect(() => {
    if (!site) {
      setSite({
        ...site,
        type_pay: "RENT",
        side_type: "OS",
        site_sub_type: "CONDO",
        zone: 1,
        site_status: 0
      });
    }
  }, [site]);

  useEffect(() => {
    const siteID = params.get("siteID");
    const adminID = params.get("adminID");

    // let datadminlist2 = QueryGraphQL(GET_ADMIN);
    // let  = QueryGraphQL(GET_SITES);
    //console.log(dataAdmin2)
    if (adminID || siteID != null) {
      if (dataSites?.length > 0) {
        dataSites.map((item) => {
          if (item?.id === siteID) {
            // console.log(item)
            let group_site_name = "";
            let province_key = "";
            let province_name = "";
            let admins_group_name = [];
            let admins_group_id = [];

            if (datadmin?.length > 0) {
              datadmin?.map((b) => {

                if (item?.admin_list?.length > 0) {
                  item?.admin_list.map((a) => {
                    if (a == b?.id) {
                      admins_group_id.push(b.id);
                      admins_group_name.push(b.admin_name);
                    }
                  });
                }
              });
            }
            if (branchGroups?.length > 0) {
              branchGroups?.map((b) => {
                if (b?.group_site_id == item?.group_site_id) {
                  group_site_name = b.group_site_name;
                }
              });
            }
            if (provinces?.length > 0) {
              provinces?.map((b) => {
                if (b?.province_key == item?.province_id) {
                  province_key = b.province_key;
                  province_name = b.province_name;
                }
              });
            }
            setIsLoadingData(false);
            setSite({
              ...item,
              group_site_name: group_site_name,
              province_key: province_key,
              province_name: province_name,
              admin_list: admins_group_id,
              admin_list_name: admins_group_name,
            });
            console.log(item);
            setLocationName(item?.site_address);
          }

        });
      } else {
        //setIsLoadingData(false);
        dispatch(locationLatLng({ lat: 0, lng: 0 }));
        setSite(undefined);
        setIsAlertAdmin(false);
        setIsAlertProvince(false);
        setIsAlertzone(false);
        setIsAlertversion(false);
        setIsAlerttier(false);
        setIsAlertmap(false);
      }
    }
    else {
      setIsLoadingData(false);
      if (datadmin?.length > 0) {
        setAdmins(datadmin || []);
      }
    }

  }, [siteId, dataSites, provinces, branchGroups, admins]);

  useEffect(() => {
    const getStaticDropdown = async () => {
      let group = await apiSGetSiteManagementGroups();
      if (group && group.status) setBranchGroups(group.result || []);
      setAdmins(datadmin || []);
      let province = await apiARGetProvince();
      if (province && province.status) setProvinces(province.result || []);
    };
    getStaticDropdown();
  }, []);

  const addEditSiteManagement = async () => {
    if (
      !site?.site_name ||
      !site?.site_name_en ||
      site?.admin_list.length == 0 ||
      isNaN(site && site.pay_amount) ||
      !site?.province_id
    ) {
      if (!site?.site_name) setIsAlertNameTH(true);
      if (!site?.site_name_en) setIsAlertNameEN(true);
      if (!site?.admin_list || site?.admin_list?.length == 0)
        setIsAlertAdmin(true);
      if (isNaN(site?.pay_amount)) setIsAlertPayAmount(true);
      if (!site?.province_id) setIsAlertProvince(true);
      if (!site?.zone) setIsAlertzone(true);
      if (!site?.version) setIsAlertversion(true);
      if (!site?.tier) setIsAlerttier(true);
      if (!site?.site_address) setIsAlertmap(true);

    } else {
      setIsLoading(true);
      const newAdmin = [];
      if (site?.admin_list?.length > 0) {
        site?.admin_list.map((list) => {
          newAdmin.push(list + "");
        });
      }
      if (siteId) {
        editSite({
          variables: {
            siteId: siteId,
            site_name: site?.site_name ?? "",
            site_name_en: site?.site_name_en + "",
            admin_list: newAdmin,
            payment_method: site?.payment_method ?? "",
            pay_amount: site?.pay_amount ? site.pay_amount + "" : "",
            side_type: site?.side_type ?? "",
            site_sub_type: site?.site_sub_type ?? "",
            group_site_id: site?.group_site_id ? site?.group_site_id + "" : "",
            unit_size: site?.unit_size ? site.unit_size + "" : "",
            province_id: site?.province_key ?? "",
            zone: site?.zone ? site.zone + "" : "",
            water_amount: site?.water_amount ? site.water_amount + "" : "",
            water_type: site?.water_type ?? "",
            elec_amount: site?.elec_amount ? site.elec_amount + "" : "",
            elec_type: site?.elec_type ?? "",
            contact_end: site?.contact_end ?? "",
            contact_start: site?.contact_start ?? "",
            latitude: place?.lat
              ? place.lat + ""
              : site?.latitude
                ? site.latitude + ""
                : "0",
            longitude: place?.lng
              ? place.lng + ""
              : site?.longitude
                ? site.longitude + ""
                : "0",
            site_address: locationName ?? "",
            site_status: site?.site_status,
            comment: site?.comment ?? "",
            tier: site?.tier ?? "",
            version: site?.version ?? "",
          },
        });
      } else {
        addSite({
          variables: {
            site_name: site?.site_name ?? "",
            site_name_en: site?.site_name_en + "",
            admin_list: newAdmin,
            payment_method: site?.payment_method ?? "",
            pay_amount: site?.pay_amount ? site.pay_amount + "" : "",
            side_type: site?.side_type ?? "",
            site_sub_type: site?.site_sub_type ?? "",
            group_site_id: site?.group_site_id ? site?.group_site_id + "" : "",
            unit_size: site?.unit_size ? site.unit_size + "" : "",
            province_id: site?.province_key ?? "",
            zone: site?.zone ? site.zone + "" : "",
            water_amount: site?.water_amount ? site.water_amount + "" : "",
            water_type: site?.water_type ?? "",
            elec_amount: site?.elec_amount ? site.elec_amount + "" : "",
            elec_type: site?.elec_type ?? "",
            contact_end: site?.contact_end ?? "",
            contact_start: site?.contact_start ?? "",
            latitude: place?.lat
              ? place.lat + ""
              : site?.latitude
                ? site.latitude + ""
                : "0",
            longitude: place?.lng
              ? place.lng + ""
              : site?.longitude
                ? site.longitude + ""
                : "0",
            site_address: locationName ?? "",
            site_status: site?.site_status,
            comment: site?.comment ?? "",
            tier: site?.tier ?? "",
            version: site?.version ?? "",
            // img_site: site.machine_group_icon_file ?? site.img_site + "" ?? "",
          },
        });
      }
    }
  };

  return (
    <div>
      {isLoadingData && <Loading />}
      <div className="mg-bt-20 h-d-t-p">
        Site Management - {siteId ? "Edit" : "Add"}{" "}
        {siteId ? `: ${site_name ?? ""}` : ""}
      </div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">ชื่อสาขา ( ไทย )</div>
        <div>
          <Input 
            //style={{ borderColor: isAlertNameTH ? "#EC1C24" : null }}
            placeholder="ชื่อสาขา ( ไทย )"
            value={(site && site.site_name) || ""}
            onChange={(e) => {
              setIsAlertNameTH(false);
              setSite({ ...site, site_name: e.target.value });
            }}
          />
        </div>
        <div className="f-s-c">ชื่อสาขา ( English )</div>
        <div>
          <Input
            
            //style={{ borderColor: isAlertNameEN ? "#EC1C24" : null }}
            placeholder="ชื่อสาขา ( English )"
            value={(site && site.site_name_en) || ""}
            onChange={(e) => {
              setIsAlertNameEN(false);
              setSite({ ...site, site_name_en: e.target.value });
            }}
          />
        </div>
        <div className="f-s-c">ผู้ดูแลสถานที่</div>
        <div className={(isAlertAdmin && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="ผู้ดูแลสถานที่"
            mode="multiple"
            showArrow
            value={site?.admin_list || []}
            onChange={(value, data) => {
              let newName = data.map((item) => {
                return item?.children;
              });
              setIsAlertAdmin(false);
              setSite({
                ...site,
                admin_list: value,
                admin_list_name: newName,
              });
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {admins.map((admin, id) => (

              <Option key={id} value={admin.id}>
                {admin.admin_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="f-s-c">การเก็บค่าเช่า</div>
        <div>
          <Select
           
            style={{ width: "100%" }}
            placeholder="การเก็บค่าเช่า"
            value={(site && site.payment_method) || undefined}
            onChange={(value) => setSite({ ...site, payment_method: value })}
          >
            <Option value="RENT">THB</Option>
            <Option value="PERCENT">%</Option>
          </Select>
        </div>
        <div className="f-s-c">จำนวน ( THB or % )</div>
        <div>
          <InputNumber
            
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            //style={{
            // width: "100%",
            //   borderColor: isAlertPayAmount ? "#EC1C24" : null,
            //}}
            placeholder="จำนวน ( THB or % )"
            value={site && site.pay_amount}
            onChange={(value) => {
              setIsAlertPayAmount(false);
              setSite({
                ...site,
                pay_amount: Math.round(value * 100) / 100 || 0,
              });
            }}
          />
        </div>
        <div className="f-s-c">ประเภทสาขา</div>
        <div>
          <Select
            disabled={true}
            style={{ width: "100%" }}
            placeholder="ประเภทสาขา"
            value={(site && site.side_type) || undefined}
            onChange={(value) => setSite({ ...site, side_type: value })}
          >
            <Option value="OS">OS</Option>
            <Option value="FS">FS</Option>
          </Select>
        </div>
        <div className="f-s-c">ประเภทสาขาย่อย</div>
        <div>
          <Select
            disabled={true}
            style={{ width: "100%" }}
            placeholder="ประเภทสาขาย่อย"
            value={(site && site.site_sub_type) || undefined}
            onChange={(value) => setSite({ ...site, site_sub_type: value })}
          >
            <Option value="CONDO">Condo</Option>
            <Option value="STANDALONE">Standalone</Option>
          </Select>
        </div>
        <div className="f-s-c">กลุ่มสาขา</div>
        <div>
          <Select
            disabled={true}
            style={{ width: "100%" }}
            placeholder="กลุ่มสาขา"
            value={site && site?.group_site_name}
            onChange={(value, data) => {
              setSite({
                ...site,
                group_site_id: value,
                group_site_name: data?.children,
              });
            }}
          >
            {branchGroups.map((group, idx) => (
              <Option key={idx} value={group.group_site_id}>
                {group.group_site_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="f-s-c">จำนวนยูนิต</div>
        <div>
          <InputNumber
            disabled={true}
            min={0}
            step={0.01}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            placeholder="จำนวนยูนิต"
            value={site && site.unit_size}
            onChange={(value) =>
              setSite({
                ...site,
                unit_size: Math.round(value * 100) / 100 || 0,
              })
            }
          />
        </div>
        <div className="f-s-c">จังหวัด</div>
        <div className={(isAlertProvince && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="จังหวัด"
            showSearch
            value={site && site?.province_name}
            onChange={(value, data) => {
              setIsAlertProvince(false);
              setSite({
                ...site,
                province_id: value,
                province_key: data?.province_key,
                province_name: data?.children,
              });
            }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {provinces.map((province, idx) => (
              <Option
                key={idx}
                value={province.province_id}
                province_key={province.province_key}
              >
                {province.province_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="f-s-c">เขตพื้นที่</div>
        <div className={(isAlertzone && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="เขตพื้นที่"
            value={(site && site.zone) || undefined}
            onChange={(value) => setSite({ ...site, zone: value })}
          >
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
            <Option value={4}>4</Option>
            <Option value={5}>5</Option>
            <Option value={6}>6</Option>
          </Select>
        </div>
        <div className="f-s-c">Site Version</div>
        <div className={(isAlertzone && "antd-sl-al-bd") || ""}>
          <Select disabled={true}
            style={{ width: "100%" }}
            placeholder="Version"
            value={(site && site.version) || undefined}
            onChange={(value) => setSite({ ...site, version: value })}
          >
            <Option value={"1"}>1</Option>
            <Option value={"2"}>2</Option>
          </Select>
        </div>
        <div className="f-s-c">Site tier</div>
        <div className={(isAlerttier && "antd-sl-al-bd") || ""}>
          <Select
            style={{ width: "100%" }}
            placeholder="tier"
            value={(site && site.tier) || undefined}
            onChange={(value) => setSite({ ...site, tier: value })}
          >
            <Option value={"A"}>A</Option>
            <Option value={"B"}>B</Option>
            <Option value={"C"}>C</Option>
          </Select>
        </div>
        <div className="f-s-c">ค่าน้ำ</div>
        <div className="site-manage-get-more-grid">
          <div>
            <Select
              disabled={true}
              style={{ width: "100%" }}
              placeholder="ค่าน้ำ"
              value={(site && site.water_type) || undefined}
              onChange={(value) => setSite({ ...site, water_type: value })}
            >
              <Option value="BAHT">THB</Option>
              <Option value="PERUNIT">Per Unit</Option>
            </Select>
          </div>
          <div>
            <InputNumber
              disabled={true}
              min={0}
              step={0.01}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
              placeholder="ค่าน้ำ"
              value={site && site.water_amount}
              onChange={(value) =>
                setSite({
                  ...site,
                  water_amount: Math.round(value * 100) / 100 || 0,
                })
              }
            />
          </div>
        </div>
        <div className="f-s-c">ค่าไฟ</div>
        <div className="site-manage-get-more-grid">
          <div>
            <Select
              disabled={true}
              style={{ width: "100%" }}
              placeholder="ค่าไฟ"
              value={(site && site.elec_type) || undefined}
              onChange={(value) => setSite({ ...site, elec_type: value })}
            >
              <Option value="BAHT">THB</Option>
              <Option value="PERUNIT">Per Unit</Option>
            </Select>
          </div>
          <div>
            <InputNumber
              disabled={true}
              min={0}
              step={0.01}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
              placeholder="ค่าไฟ"
              value={site && site.elec_amount}
              onChange={(value) =>
                setSite({
                  ...site,
                  elec_amount: Math.round(value * 100) / 100 || 0,
                })
              }
            />
          </div>
        </div>
        <div className="f-s-c">สัญญา</div>
        <div>
          <RangePicker
            disabled={true}
            style={{ width: "100%" }}
            placeholder={["วันเริ่มต้น", "วันสิ้นสุด"]}
            format={(date) =>
              moment(date).add(543, "years").format("D MMM YYYY")
            }
            allowClear={false}
            value={[
              (site &&
                site.contact_start &&
                site.contact_start !== "0000-00-00 00:00:00" &&
                moment(site.contact_start, "YYYY-MM-DD HH:mm:ss")) ||
              undefined,
              (site &&
                site.contact_end &&
                site.contact_end !== "0000-00-00 00:00:00" &&
                moment(site.contact_end, "YYYY-MM-DD HH:mm:ss")) ||
              undefined,
            ]}
            onChange={(date) =>
              setSite({
                ...site,
                contact_start: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
                contact_end: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
              })
            }
          />
        </div>
        <div className="f-s-c">แผนที่</div>
        <SiteManagementMaps className={(isAlertmap && "antd-sl-al-bd") || ""}
          latitude={site?.latitude ?? ""}
          longitude={site?.longitude ?? ""}
          setLocationName={(value) => setLocationName(value)}
          locationName={site?.site_address}
        />
        <div className="f-s-c">สถานะ</div>
        <div>
          <Select
            style={{ width: "100%" }}
            placeholder="สถานะ"
            value={(site && site.site_status) || undefined}
            onChange={(value) => {
              setSite({ ...site, site_status: value });
            }}
            disabled
          >
            <Option value="golive">กำลังใช้งาน</Option>
            <Option value="systemtest">เทสระบบ</Option>
            <Option value="installing">กำลังติดตั้งสาขา</Option>
            <Option value="cancel">ยกเลิก</Option>
          </Select>
        </div>
        <div className="f-s-c">Comment</div>
        <div>
          <Input.TextArea
            autoSize={{ minRows: 3 }}
            placeholder="Comment"
            value={(site && site.comment) || ""}
            onChange={(e) => setSite({ ...site, comment: e.target.value })}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: "160px", pointerEvents: isLoading ? "none" : null }}
          onClick={() => addEditSiteManagement()}
        >
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            `${siteId ? "Edit" : "Add New"} Site`
          )}
        </div>
      </div>

    </div>
  );
};

export default SiteManagementAddEditV2;
