import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Table, Input, Pagination, Tooltip, Select, Modal } from "antd";
import "./Machine.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

// import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
// import arrayMove from 'array-move';

import moment from "moment";

import {
  numberWithCommas,
  openNotification,
  getSearchParams,
} from "../../utils/function";
import { changeModalMachineAction } from "../../redux/machine";
import {
  apiMCHGetMachine,
  apiMCHDeleteMachine,
  apiMCHGetTypeBySiteId,
} from "../../services/apis/machine";
import { apiSGetSite } from "../../services/apis/site";

import ModalMachineAction from "../../components/Modal/ModalMachineAction";

const { Option } = Select;

const MachineListPage = () => {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const mcht = params.get("mcht");
  const s = params.get("s");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");
  const role = (localStorage.getItem("user_role") || "").toUpperCase();

  const [isLoading, setIsLoading] = useState(false);
  const [machineTypes, setMachineTypes] = useState([]);
  const [sites, setSites] = useState([]);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [tableData, setTableData] = useState(undefined);
  const [dataSource, setDataSource] = useState([]);
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [machineType, setMachineType] = useState(Number(mcht) || "");
  const [site, setSite] = useState(
    Number(s) ||
      (role === "SUPERADMIN" ? "" : localStorage.getItem("site_id")) ||
      ""
  );

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [modalActionData, setModalActionData] = useState(undefined);

  useEffect(() => {
    const getStaticDropdown = async () => {
      let type = await apiMCHGetTypeBySiteId("");
      if (type && type.status) setMachineTypes(type.result || []);

      let site = await apiSGetSite();
      if (site && site.status) setSites(site.result || []);
    };
    getStaticDropdown();
  }, []);

  useEffect(() => {
    getTableData();
    history.push(
      `${location.pathname}${getSearchParams(
        ["p", "ttl", "mcht", "s", "sw"],
        [currentPage, perPage, machineType, site, searchWord]
      )}`
    );
  }, [currentPage, perPage, searchButton, machine.reloadMachineList]);

  const getTableData = async () => {
    setIsLoading(true);
    let machine = await apiMCHGetMachine(
      false,
      currentPage,
      perPage,
      searchWord,
      machineType,
      site
    );
    console.log(machine);
    if (machine && machine.status) {
      setIsLoading(false);
      setTableData(machine.result);
      setDataSource((machine.result && machine.result.data_list) || []);
    }
  };

  // const DragHandle = sortableHandle(() => <i className="bi bi-arrow-down-up" style={{ fontSize: '20px', cursor: 'pointer' }}></i>)
  // const SortableItem = sortableElement(props => <tr {...props} />);
  // const SortableContainer = sortableContainer(props => <tbody {...props} />);

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   if (oldIndex !== newIndex) {
  //     const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
  //     setDataSource(newData)
  //   }
  // };

  // const DraggableContainer = props => (
  //   <SortableContainer
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass="machine-list-row-dragging"
  //     onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );

  // const DraggableBodyRow = ({ className, style, ...restProps }) => {
  //   const index = dataSource.findIndex(x => x.machine_id === restProps['data-row-key']);
  //   return <SortableItem index={index} {...restProps} />;
  // };

  const columns = [
    // {
    //   title: 'เรียงลำดับ',
    //   ellipsis: true,
    //   align: 'center',
    //   className: 'machine-list-drag-visible',
    //   render: () => <DragHandle />,
    // },
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      className: "machine-list-drag-visible",
      render: (data) => <div>{data.machine_order || "-"}</div>,
    },
    {
      title: "ประเภท",
      ellipsis: true,
      className: "machine-list-drag-visible",
      render: (data) => <div>{data.machine_type || "-"}</div>,
    },
    {
      title: "สถานที่",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "ประเภท - ย่อย",
      ellipsis: true,
      render: (data) => <div>{data.machine_sub_type || "-"}</div>,
    },
    {
      title: "ชื่อ",
      ellipsis: true,
      render: (data) => <div>{data.machine_name || "-"}</div>,
    },
    {
      title: "รหัส",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.machine_mac_address || "-"}</div>,
    },
    {
      title: "สถานะ",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.machine_status || "-"}</div>,
    },
    {
      title: "ออนไลน์ล่าสุด",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.lastupdate ? moment(data.lastupdate).fromNow() : "-"}</div>
      ),
    },
    {
      title: "ราคา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{numberWithCommas(data.machine_price || 0, 2)}</div>
      ),
    },
    {
      title: "ใส่กล่อง",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.is_coin_box ? "ใส่" : "ไม่ใส่"}</div>,
    },
    {
      title: "ตั้งค่า",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) =>
        role === "BRANCHADMIN" ? (
          <Tooltip placement="top" title="History">
            <div
              className="f-c-c d-b-t-n-s b-c-o-i"
              style={{ backgroundColor: "#45D88E" }}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/machine/list/history/${data.machine_id}`);
              }}
            >
              <i className="bi bi-clock-history"></i>
            </div>
          </Tooltip>
        ) : (
          <div className="f-c-c">
            <div
              className={
                menuList.includes("MACHINEMAN")
                  ? "tb-cl-a-g tb-cl-a-g-rp-2"
                  : "f-c-c tb-cl-a"
              }
            >
              <Tooltip placement="top" title="History">
                <div
                  className="f-c-c d-b-t-n-s b-c-o-i"
                  style={{ backgroundColor: "#45D88E" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/machine/list/history/${data.machine_id}`);
                  }}
                >
                  <i className="bi bi-clock-history"></i>
                </div>
              </Tooltip>
              {menuList.includes("MACHINEMAN") && (
                <Tooltip placement="top" title="Edit">
                  <div
                    className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: "#00ADEF" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(
                        `/machine/list/edit/${data.machine_id}${getSearchParams(
                          ["p", "ttl", "mcht", "s", "sw"],
                          [currentPage, perPage, machineType, site, searchWord]
                        )}`
                      );
                    }}
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </div>
                </Tooltip>
              )}
              {menuList.includes("MACHINEMAN") && (
                <Tooltip placement="top" title="Delete">
                  <div
                    className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: "#ED2228" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalId(data.machine_id);
                      setModalTitle(data.machine_name);
                      setVisible(true);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </div>
                </Tooltip>
              )}
              {menuList.includes("MACHINEMAN") && (
                <Tooltip placement="top" title="Action">
                  <div
                    className="f-c-c d-b-t-n-s b-c-o-i"
                    style={{ backgroundColor: "#FFCB05" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(changeModalMachineAction(true));
                      setModalActionData(data);
                      
                    }}
                  >
                    <i className="fas fa-cogs"></i>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        ),
    },
  ];

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      return;
    }

    setIsLoadingConfirm(true);
    let action = await apiMCHDeleteMachine(modalId);
    if (action && action.status) {
      openNotification(
        "success",
        "Success !",
        "Delete this machine successful."
      );
      setVisible(false);
      setIsLoadingConfirm(false);
      getTableData();
    } else {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      setIsLoadingConfirm(false);
    }
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let data = await apiMCHGetMachine(
      role === "SUPERADMIN",
      "",
      "",
      searchWord,
      machineType,
      site
    );
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {};
        resultRow["#"] = data.machine_order || "-";
        resultRow["ประเภท"] = data.machine_type || "-";
        resultRow["ประเภท - ย่อย"] = data.machine_sub_type || "-";
        resultRow["ชื่อ"] = data.machine_name || "-";
        resultRow["รหัส"] = data.machine_mac_address || "-";
        resultRow["สถานะ"] = data.machine_status || "-";
        resultRow["ราคา"] = data.machine_price || 0;
        resultRow["สถานที่"] = data.site_name || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "MACHINE_LIST";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  const getColorStatus = (status) => {
    switch (status) {
      case "WORKING":
        return "#00ADEF";
      case "OFFLINE":
        return "#EC1C24";
      case "ONLINE":
        return "#45D88E";
      default:
        return "#CCCCCC";
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Machine List</div>

      <div
        className={`machine-list-container-grid machine-list-container-grid-${role.toLowerCase()}-${!menuList.includes(
          "MACHINEMAN"
        )}`}
      >
        <div
          className={`machine-list-container-grid-${role.toLowerCase()}-select-type`}
        >
          <Select
            className="machine-list-select-width"
            value={machineType}
            onChange={(value) => setMachineType(value)}
          >
            <Option value="">All Machine Type</Option>
            {machineTypes.map((type, idx) => (
              <Option key={idx} value={type.machine_type_id}>
                {type.machine_type_name}
              </Option>
            ))}
          </Select>
        </div>
        {role === "SUPERADMIN" && (
          <div
            className={`machine-list-container-grid-${role.toLowerCase()}-select-site`}
          >
            <Select
              className="machine-list-select-width"
              showSearch
              value={site}
              onChange={(value) => setSite(value)}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">All Site</Option>
              {sites.map((site, idx) => (
                <Option key={idx} value={site.site_id}>
                  {site.site_name}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div
          className={`machine-list-container-grid-${role.toLowerCase()}-search`}
        >
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t"
          onClick={() => {
            setCurrentPage(1);
            setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {role === "SUPERADMIN" && menuList.includes("MACHINEMAN") && (
          <Link to="/machine/add">
            <div
              className="f-c-c d-b-t-n-s d-bg-c-t h-32"
              style={{ height: "100%" }}
            >
              + Add
            </div>
          </Link>
        )}
        {role === "SUPERADMIN" && (
          <div
            className={`f-c-c d-b-t-n-s d-bg-c-t h-32 machine-list-container-grid-${role.toLowerCase()}-export-${
              !menuList.includes("MACHINEMAN") ? "1" : "2"
            }`}
            style={{ pointerEvents: waitingForCSV ? "none" : null }}
            onClick={() => toCSVFile()}
          >
            {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
          </div>
        )}
      </div>

      {role === "SUPERADMIN" && (
        <Table
          bordered
          size="small"
          loading={isLoading}
          rowKey={(record) => record.machine_id}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          // components={{
          //   body: {
          //     wrapper: DraggableContainer,
          //     row: DraggableBodyRow,
          //   },
          // }}
        />
      )}

      {role === "SUPERADMIN" && (
        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={(page) => setCurrentPage(page)}
            total={tableData && tableData.max_total_rows}
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>
      )}

      {role === "BRANCHADMIN" && (
        <div className="machine-list-container-machine-flex">
          {dataSource.map((machine, idx) => (
            <div
              key={idx}
              className="c-c-t-s cs-pt machine-list-container-machine-card"
              onClick={() =>
                history.push(`/machine/list/history/${machine.machine_id}`)
              }
            >
              <div className="f-c-c">{machine.machine_name}</div>
              <div className="f-c-c machine-list-container-machine-image">
                <img
                  src={`/assets/image/machine/${
                    machine.machine_type === "เครื่องซักผ้า" ? "blue" : "orange"
                  }.png`}
                  alt=""
                />
              </div>
              <div
                className="f-c-c"
                style={{
                  color: getColorStatus(machine.machine_status.toUpperCase()),
                }}
              >
                {machine.machine_status}
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>

      <ModalMachineAction modalActionData={modalActionData} />
    </div>
  );
};

export default MachineListPage;
