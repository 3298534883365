import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Select, Checkbox } from 'antd';
import './Modal.css'
import { LoadingOutlined } from '@ant-design/icons';

import { Link, useHistory, useLocation } from "react-router-dom";
import { numberWithCommas, openNotification } from '../../utils/function'
import { changeModalMachineAction, changeReloadStatus } from '../../redux/machine'
import { apiCTMGetAllCustomer } from '../../services/apis/customer'
import { apiMCHActionMachine, apistartMachine_admin } from '../../services/apis/machine'

const { Option } = Select;

const ModalMachineAction = props => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const mcht = params.get("mcht");
  const siteCode = params.get("s");
  const sw = params.get("sw");
  const dispatch = useDispatch();
  const machine = useSelector(state => state.machine);
  const [customers, setCustomers] = useState([])
  const [modalActionData, setModalActionData] = useState(undefined)
  console.log(modalActionData)
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [initPrice, setInitPrice] = useState(0)
  const [isAlertCustomer, setIsAlertCustomer] = useState(false)
  const [calTimeDryer, setCalTimeDryer] = useState(0)

  const user_name = (localStorage.getItem("user_name") || "").toUpperCase();
  const modes = {
    เครื่องซักผ้า: [
      {
        key: 1,
        name: "น้ำเย็น",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม", "เครื่องอุตสาหกรรมจีน"],
      },
      {
        key: 2,
        name: "น้ำอุ่น",
        value: 10,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม", "เครื่องอุตสาหกรรมจีน"],
      },
      {
        key: 3,
        name: "น้ำร้อน",
        value: 20,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม", "เครื่องอุตสาหกรรมจีน"],
      },
      {
        key: 4,
        name: "ผ้าห่มน้ำเย็น",
        value: 0,
        filter: ["เครื่องอุตสาหกรรม"],
      },
      {
        key: 5,
        name: "ถนอมผ้าน้ำอุ่น",
        value: 10,
        filter: ["เครื่องอุตสาหกรรม"],
      },
      {
        key: 6,
        name: "ถนอมผ้าน้ำเย็น",
        value: 0,
        filter: ["เครื่องอุตสาหกรรม"],
      },
    ],
    เครื่องอบผ้า: [
      {
        key: 1,
        name: "ร้อนปกติ",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม"],
      },
      {
        key: 2,
        name: "ร้อนปานกลาง",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม"],
      },
      {
        key: 3,
        name: "ร้อนสูง",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม"],
      },
      { key: 4, name: "ผ้าบอบบาง", value: 0, filter: ["เครื่องอุตสาหกรรม"] },
    ],
  };

  const options = {
    เครื่องซักผ้า: [
      {
        key: 1,
        name: "เพิ่มรอบล้าง",
        value: 10,
        filter: ["เครื่องพานิช", "เครื่องอุตสาหกรรม"],
      },
      { key: 2, name: "เพิ่มรอบซัก", value: 10, filter: ["เครื่องอุตสาหกรรม"] },
    ],
  };

  useEffect(() => {
    if (machine.isModalMachineAction) {
      let initCalTimeDryer = 0
      let propsData = props.modalActionData
      propsData.machine_price = Number(props.modalActionData.machine_price || 0)
      propsData.action_time = Number(props.modalActionData.action_time || 0)

      let newData = {
        _action_by: 'coin',
        _mode: 1,
        _customer_id: undefined,
        _options: [],
      }

      if (propsData.machine_type === 'เครื่องอบผ้า') {
        switch (propsData.machine_sub_type) {
          case 'เครื่องพานิช': initCalTimeDryer = 10
            break;
          case 'เครื่องอุตสาหกรรม': initCalTimeDryer = 6
            break;
          default: initCalTimeDryer = ((Number(propsData.machine_default_time || 0)) * 10) / (Number(propsData.machine_price || 0) * 60)
            break;
        }
      }

      setCalTimeDryer(initCalTimeDryer)
      setInitPrice(propsData.machine_price)
      setModalActionData({ ...newData, ...propsData })
      setIsAlertCustomer(false)
    }
  }, [machine.isModalMachineAction])

  useEffect(() => {
    const getStaticDropdown = async () => {
      let customer = await apiCTMGetAllCustomer('')
      if (customer && customer.status) setCustomers(customer.result || [])
    }
    getStaticDropdown()
  }, [])

  const displayMode = () => {
    switch (modalActionData && modalActionData.machine_sub_type) {
      case 'เครื่องพานิช':
      case 'LG commercial':
      case 'เครื่องอุตสาหกรรม':
      case 'เครื่องอุตสาหกรรมจีน':
        return true

      default: return false
    }
  }

  const displayOptions = () => {
    if (modalActionData && modalActionData.machine_type !== 'เครื่องซักผ้า') return false

    switch (modalActionData && modalActionData.machine_sub_type) {
      case 'เครื่องพานิช':
      case 'เครื่องอุตสาหกรรม':
        return true

      default: return false
    }
  }

  const displayTimeChnage = () => {
    if (modalActionData && modalActionData.machine_type !== 'เครื่องซักผ้า') return true
    return false
  }

  useEffect(() => {
    if (modalActionData) {
      if (modalActionData && modalActionData.machine_type !== 'เครื่องซักผ้า') return

      let modePrice = 0
      let mode = (modes[modalActionData && modalActionData.machine_type] || []).find(list => list.key === modalActionData._mode)
      if (mode) modePrice = mode.value

      let optionPrice = 0;
      (options[modalActionData && modalActionData.machine_type] || []).map(option => {
        if (modalActionData._options.includes(option.key)) optionPrice += option.value
      })

      setModalActionData({ ...modalActionData, machine_price: initPrice + modePrice + optionPrice })
    }
  }, [modalActionData && modalActionData._mode, modalActionData && modalActionData._options && modalActionData._options.length])

  const actionData = async () => {
    if (modalActionData && modalActionData._action_by === 'mobile' && !(modalActionData && siteCode)) setIsAlertCustomer(true)
    else {
      let data = {
        "site_code": siteCode,
        "device_name": modalActionData?.machine_mac_address,
        "customer_id": "1",
        "customer_name":  "Tw admin : "+ user_name||"",
        "action_price":modalActionData?.machine_price + "",
        "action_via": "mobile",
        "promotion_id": "",
        "mode": modalActionData._mode + "",
        "option": "",
        "price_default": modalActionData?.machine_price + "",
        "action": "1",
        "action_time": modalActionData?.action_time+ "" || "600"
      }
      console.log(data)
      let resData = await apistartMachine_admin(data)
      if (resData.startDevice.status) {
        dispatch(changeReloadStatus())
        dispatch(changeModalMachineAction(false))
        setIsLoadingConfirm(false)
        openNotification('success', 'Success !', resData.startDevice.message && `Success with status : ${ resData.startDevice.message}.` || 'Action machine successful.')
      } else {
        setIsLoadingConfirm(false)
        openNotification('error', 'Failed !', resData.startDevice.message && `Failed with status : ${ resData.startDevice.message}.` || 'Failed to action machine.')
      }

      // let data = new FormData()
      // data.append('no', modalActionData.machine_mac_address)
      // data.append('action', 1)
      // data.append('actionby', modalActionData._action_by)
      // data.append('customer_id', modalActionData._customer_id || 1)
      // data.append('actiontime', modalActionData.action_time || 0)
      // data.append('price', modalActionData.machine_price || 0)
      // data.append('mode', modalActionData._mode)
      // data.append('option', modalActionData._options)

      // let newData = await apiMCHActionMachine(data)
      // if (newData && newData.status) {
      //   dispatch(changeReloadStatus())
      //   dispatch(changeModalMachineAction(false))
      //   setIsLoadingConfirm(false)
      //   openNotification('success', 'Success !', newData.message && `Success with status : ${newData.message}.` || 'Action machine successful.')
      // } else {
      //   setIsLoadingConfirm(false)
      //   openNotification('error', 'Failed !', newData.message && `Failed with status : ${newData.message}.` || 'Failed to action machine.')
      // }
    }
  }

  return (
    <div>
      <Modal
        title=""
        visible={machine.isModalMachineAction}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-t-q"> เครื่องหมายเลข : {modalActionData && modalActionData.machine_name || ''}</div>

        <div className="t-m-c-i-p-g mg-t-10">
          {displayMode() && <div className="f-s-c">Mode</div>}
          {displayMode() && (
            <div>
              {(modes[modalActionData && modalActionData.machine_type] || [])
                .filter((list) =>
                  list.filter.includes(
                    modalActionData && modalActionData.machine_sub_type
                  )
                )
                .map((mode, idx) => (

                  <div key={idx} className="f-s-c">
                    <Checkbox
                      checked={
                        modalActionData && modalActionData._mode === mode.key
                      }
                      onChange={(e) =>
                        setModalActionData({
                          ...modalActionData,
                          _mode: mode.key,
                        })
                      }
                    >
                      {mode.name}
                    </Checkbox>
                  </div>
                ))}
            </div>
          )}
          {displayOptions() && <div className="f-s-c">Options Wash</div>}
          {displayOptions() && (
            <div>
              {(options[modalActionData && modalActionData.machine_type] || [])
                .filter((list) =>
                  list.filter.includes(
                    modalActionData && modalActionData.machine_sub_type
                  )
                )
                .map((option, idx) => (
                  <div key={idx} className="f-s-c">
                    <Checkbox
                      checked={
                        modalActionData &&
                        modalActionData._options.includes(option.key)
                      }
                      onChange={(e) => {
                        let newData = { ...modalActionData };

                        if (e.target.checked) newData._options.push(option.key);
                        else
                          newData._options = modalActionData._options.filter(
                            (list) => list !== option.key
                          );

                        setModalActionData(newData);
                      }}
                    >
                      {option.name}
                    </Checkbox>
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="mg-t-20 t-al-c" style={{ fontSize: '20px' }}>
          <div>เวลา {numberWithCommas((modalActionData && modalActionData.action_time || 0) / 60, 0)} นาที</div>
          <div>ราคา {numberWithCommas(modalActionData && modalActionData.machine_price || 0, 2)} บาท</div>
        </div>

        {displayTimeChnage() && <div className="machine-action-add-time-grid">
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={(modalActionData && modalActionData.action_time || 0) / 60 < calTimeDryer || (modalActionData && modalActionData.machine_price || 0) < 10 // time and price
              ? { backgroundColor: '#cccccc', pointerEvents: 'none' }
              : { backgroundColor: '#3f4d67' }
            }
            onClick={() => modalActionData.action_time - (calTimeDryer * 60) >= 0 && modalActionData.machine_price - 10 >= 0
              ? setModalActionData({
                ...modalActionData,
                action_time: modalActionData.action_time - (calTimeDryer * 60),
                machine_price: modalActionData.machine_price - 10
              })
              : null
            }
          >-</div>
          <div className="f-c-c">เพิ่ม / ลดเวลา</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67' }}
            onClick={() => setModalActionData({
              ...modalActionData,
              action_time: modalActionData.action_time + (calTimeDryer * 60),
              machine_price: modalActionData.machine_price + 10
            })}
          >+</div>
        </div>}

        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => dispatch(changeModalMachineAction(false))}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirm ? 'none' : null }}
            onClick={() => actionData()}
          >{isLoadingConfirm ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>
    </div >
  )
}

export default ModalMachineAction
