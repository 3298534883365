import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Input, DatePicker, Checkbox } from 'antd';
import './Customer.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, checkReqFieldWithId, getSearchParams } from '../../utils/function'
import { apiCTMGetCustomerById, apiCTMAddCustomer, apiCTMEditCustomerById } from '../../services/apis/customer'
import { customerByid, updateCustomerdata } from '../../services/apis/mobileApp'
const CustomerAddEdit = () => {
  const { customerId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search)
  const p = params.get('p')
  const ttl = params.get('ttl')
  const sw = params.get('sw')

  const [isLoading, setIsLoading] = useState(false)
  const [customer, setCustomer] = useState(undefined)
  const genders = ['Male', 'Female', 'Hermaphrodite']

  const [isAlertName, setIsAlertName] = useState(false)
  const [isAlertTel, setIsAlertTel] = useState(false)
  const [isAlertEmail, setIsAlertEmail] = useState(false)
  const [isAlertPassword, setIsAlertPassword] = useState(false)

  useEffect(() => {
    if (customerId) getData()
    else {
      setCustomer(undefined)
      setIsAlertName(false)
      setIsAlertTel(false)
      setIsAlertEmail(false)
      setIsAlertPassword(false)
    }
  }, [customerId])

  useEffect(() => {
    if (!customer) {
      setCustomer({
        ...customer,
        customer_bday: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        customer_gender: 'male'
      })
    }
  }, [customer])

  const getData = async () => {
    let data = await customerByid(customerId)

    if (data.status) setCustomer(data.data)
  }

  const addEditData = async () => {

    if (!(customer && customer.name) ||
      !customer.password
    ) {
      if (!(customer && customer.name)) setIsAlertName(true)
      if (!customer.password) setIsAlertPassword(true)

    } else {
      setIsLoading(true)

      let data = { ...customer }
     
      let update = {
        "name": data.name,
        "customer_password": data.password
      }
      let newData = await updateCustomerdata(update,data._id)
      if (newData.status) {
        setIsLoading(false)
        openNotification('success', 'Success !', `${customerId ? 'Edit' : 'Add new'} customer successful.`)
        setTimeout(() => history.push(`/customerMobileapp/list?p=1&ttl=20`), 1000);
      } else {
        setIsLoading(false)
        openNotification('error', 'Failed !', `Failed to ${customerId ? 'edit' : 'add new'} customer.`)
      }
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Customer - {customerId ? 'Edit' : 'Add'}</div>

      <div className="t-m-c-i-p-g">
        <div className="f-s-c">Name</div>
        <div>
          <Input
            placeholder="Name"
            value={customer && customer.name || ''}
            onChange={e => {
              setCustomer({ ...customer, name: e.target.value })
            }}
          />
        </div>

        <div className="f-s-c">Tel.</div>
        <div>
          <Input
            placeholder="Tel."
            value={customer && customer.tel || ''}
            onChange={e => {
              setCustomer({ ...customer, tel: e.target.value.replace(/[^0-9]+/g, '') })
            }}
            disabled
          />
        </div>
        <div className="f-s-c">Line</div>
        <div>
          <Input
            placeholder="Line"
            value={customer && customer.line_id || ''}
            onChange={e => {
              setCustomer({ ...customer, line_id: e.target.value })
            }}
            disabled
          />
        </div>
        <div className="f-s-c">Google</div>
        <div>
          <Input
            placeholder="Google"
            value={customer && customer.google_id || ''}
            onChange={e => {

              setCustomer({ ...customer, google_id: e.target.value })
            }}
            disabled
          />
        </div>
        <div className="f-s-c">E-mail</div>
        <div>
          <Input
            placeholder="E-mail"
            value={customer && customer.email || ''}
            onChange={e => {
              setCustomer({ ...customer, email: e.target.value })
            }}
          />
        </div>


        <div className="f-s-c">Password</div>
        <div>
          <Input.Password
            style={{ borderColor: isAlertPassword ? '#EC1C24' : null }}
            placeholder="Password"
            value={customer && customer.password || ''}
            onChange={e => {
              setIsAlertPassword(false)
              setCustomer({ ...customer, password: e.target.value })
            }}
          />
        </div>
      </div>

      <div className="f-c-c s-e-b">
        <div className="f-c-c d-b-t-n-s d-bg-c-t"
          style={{ width: '170px', pointerEvents: isLoading ? 'none' : null }}
          onClick={() => addEditData()}
        >{isLoading ? <LoadingOutlined /> : `${customerId ? 'Edit' : 'Add New'} Customer`}</div>
      </div>

    </div>
  )
}

export default CustomerAddEdit
