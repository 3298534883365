import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Payment.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import {
  numberWithCommas,
  openNotification,
  getSearchParams,
} from "../../utils/function";
import {
  apiPMGetPayment,
  apiPMApproveRejectPayment,
} from "../../services/apis/payment";
import { apiSCBTransactionId } from "../../services/apis/transaction";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Payment = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [tableData, setTableData] = useState(undefined);
  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visibleTnxId, setVisibleTnxId] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    getTableData();
    history.push(
      `${location.pathname}${getSearchParams(
        ["p", "ttl", "pmt", "start", "end", "sw"],
        [currentPage, perPage, filterOption, startDate, endDate, searchWord]
      )}`
    );
  }, [currentPage, perPage, searchButton]);

  const getTableData = async () => {
    setIsLoading(true);
    let payment = await apiPMGetPayment(
      false,
      startDate,
      endDate,
      currentPage,
      perPage,
      filterOption,
      searchWord
    );
    
    if (payment && payment.status) {
      setIsLoading(false);
     // console.log("Payment Result: ", payment.result);
    // console.log(payment.result.data_list);
     let check = [], datalist;
     datalist = payment.result.data_list;
     for (let i = 0; i < datalist.length; i++) {
       check.push(datalist[i].customer_id);
     }
     console.log(check);
 const a = ["red", "green", "blue", "green", "blue"];
    var b = [];
    var c = []
    for( var i=0; i<check.length; i++ ) {
        if ( b.indexOf( check[i] ) < 0 ) {
            b.push( check[i] );
        } else {
            c.push( check[i] );
        }
    }
    console.log( "Array เดิม = "+a );
    console.log( "Array ที่ลบค่าซ้ำ = "+b );
    console.log( "Array เฉพาะค่าซ้ำ = "+c );
      setTableData(payment.result);
    }
  };

  const getTransactionId = async (tnx_id) => {
    setIsLoadingConfirm(true);
    let action = await apiSCBTransactionId(tnx_id);
    if (action?.data?.transaction_id) {
      setTransactionId(action?.data?.transaction_id ?? "");
      setVisibleTnxId(true);
      setIsLoadingConfirm(false);
    } else {
      setIsLoadingConfirm(false);
    }
  };

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "วันที่",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.date
            ? moment(data.date).add(543, "years").format("D MMM YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "เวลา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.date ? moment(data.date).format("HH:mm:ss") : "-"}</div>
      ),
    },
    {
      title: "ลูกค้า",
      ellipsis: true,
      className: "cs-pt",
      render: (data) => (
        <div
          className="cs-pt cl-g"
          onClick={() =>
            window.open(`/customer/list/${data.customer_id}`, "_blank")
          }
        >
          {data.customer_name || "-"}
        </div>
      ),
    },
    {
      title: "โปรโมชั่นโค้ด",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.promo_code || "-"}</div>,
    },
    {
      title: "ช่องทาง",
      ellipsis: true,
      align: "center",
      render: (data) =>
        data.payment_type == "TW Pay" ? (
          <div
            onClick={() => getTransactionId(data?.payment_id)}
            className="cs-pt cl-g"
          >
            TW Pay
          </div>
        ) : (
          <div>{data.payment_type || "-"}</div>
        ),
    },
    {
      title: "จำนวนเงิน",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.amount || 0, 2)}</div>,
    },
    {
      title: "สถานะ",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.status || "-"}</div>,
    },
    {
      title: "ยืนยัน",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (data) =>
        (data.status || "").toUpperCase() === "APPROVE" ||
        (data.status || "").toUpperCase() === "REJECT" ||
        (data.status || "").toUpperCase() === "COMPLETE" ? (
          <div>-</div>
        ) : (
          <div className="f-c-c tb-cl-a">
            <Tooltip placement="top" title="Approve">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#00ADEF" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalId(data.payment_id);
                  setModalTitle("Approve");
                  setVisible(true);
                }}
              >
                <i className="bi bi-check-circle"></i>
              </div>
            </Tooltip>
            <Tooltip placement="top" title="Reject">
              <div
                className="f-c-c d-b-t-n-s b-c-o-i"
                style={{ backgroundColor: "#ED2228" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalId(data.payment_id);
                  setModalTitle("Reject");
                  setVisible(true);
                }}
              >
                <i className="bi bi-x-circle"></i>
              </div>
            </Tooltip>
          </div>
        ),
    },
  ];

  const approveReject = async () => {
    if (!modalId || !modalTitle) {
      openNotification("error", "Failed !", "Failed to action this payment.");
      return;
    }

    let data = {
      payment_id: modalId,
      status: modalTitle.toUpperCase(),
    };

    setIsLoadingConfirm(true);
    let action = await apiPMApproveRejectPayment(data);
    if (action && action.status) {
      openNotification(
        "success",
        "Success !",
        "Action this payment successful."
      );
      setVisible(false);
      setIsLoadingConfirm(false);
      getTableData();
    } else {
      openNotification("error", "Failed !", "Failed to action this payment.");
      setIsLoadingConfirm(false);
    }
  };

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let data = await apiPMGetPayment(
      true,
      startDate,
      endDate,
      currentPage,
      perPage,
      filterOption,
      searchWord
    );
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {};
        resultRow["วันที่"] = data.date
          ? moment(data.date).add(543, "years").format("D MMM YYYY")
          : "-";
        resultRow["เวลา"] = data.date
          ? moment(data.date).format("HH:mm:ss")
          : "-";
        resultRow["ลูกค้า"] = data.customer_name || "-";
        resultRow["โปรโมชั่นโค้ด"] = data.promo_code || "-";
        resultRow["ช่องทาง"] = data.payment_type || "-";
        resultRow["จำนวนเงิน"] = numberWithCommas(data.amount || 0, 2);
        resultRow["สถานะ"] = data.status || "-";
        resultRow["ยืนยัน"] = data.status || "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "PAYMENT";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Payment</div>

      <div className="h-d-t-g-w-d-d-s">
        <div className="h-d-t-g-w-d-d-s-order-2">
          <Select
            value={filterOption}
            style={{ width: "100%" }}
            onChange={(value) => {
              setCurrentPage(1);
              setFilterOption(value);
            }}
          >
            <Option value="">All Payment Type</Option>
            <Option value="qr_test">TW Pay</Option>
            <Option value="linepay">Line Pay</Option>
            <Option value="truemoney">True Money Wallet</Option>
            <Option value="omise">Credit Card</Option>
            <Option value="alipay">Alipay</Option>
            <Option value="scb">Thai QR-Code</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
        <RangePicker
            style={{ width: "100%" }}
            // format={(date) =>  moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div className="h-d-t-g-w-d-d-s-order-3">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            setCurrentPage(1);
            setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.payment_id}
        dataSource={(tableData && tableData.data_list) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={tableData && tableData.max_total_rows}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Confirm to {modalTitle} ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => approveReject()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={visibleTnxId}
        closable={false}
        footer={false}
        centered
      >
        <div className="f-c-c">Transaction ID = {transactionId}</div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => {
            setVisibleTnxId(false);
          }}
        >
          OK
        </div>
      </Modal>
    </div>
  );
};

export default Payment;
