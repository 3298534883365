import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Payment.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import Papa from 'papaparse';
import {
  numberWithCommas,
  getSearchParams,
  openNotification,
} from "../../utils/function";
import {
  apiTRSTGetTransaction,
  apiTRSTDel,
  apiSCBTransactionId,
} from "../../services/apis/transaction";
import {
  getPaymentSCB, getPaymentSCB_groupsite, UploadPaymentSCB
} from "../../services/apis/report";
const { RangePicker } = DatePicker;
const { Option } = Select;

const PaymentSCB = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const perPage = 20;

  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  console.log(startDate, endDate)
  const [tableData, setTableData] = useState(undefined);
  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);
  const [waitingForDel, setWaitingForDel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [visibleTnxId, setVisibleTnxId] = useState(false);
  const [transactionlength, setransactionlength] = useState(0);


  const [tableDataPapa, setTableDataPapa] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [currentPagePapa, setCurrentPagePapa] = useState(1);
  const rowsPerPage = 10; // Number of rows per page
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {

          setHeaders(result.data[0]); // First row as headers
          setTableDataPapa(result.data.slice(1)); // Rest as data rows
        },
        encoding: "UTF-8", // Set encoding to handle Thai characters
        header: false,
      });
    }
  };
  const upload_file = async () => {
    // console.log(headers);
    // console.log(tableDataPapa.length);

    for (let i = 0; i < tableDataPapa.length-1; i++) {
        let data = {
          "Date": tableDataPapa[i][1],
          "Time": tableDataPapa[i][2],
          "Customer_Name": tableDataPapa[i][3],
          "Customer_Ref1": tableDataPapa[i][4],
          "Customer_Ref2": tableDataPapa[i][5],
          "Customer_Ref3": tableDataPapa[i][6],
          "Amount": tableDataPapa[i][7],
          "Branch": tableDataPapa[i][8],
          "Code": tableDataPapa[i][9],
          "Cheque_Type": tableDataPapa[i][10],
          "Cheque_No": tableDataPapa[i][11],
          "Channel": tableDataPapa[i][12],
          "Service_Bank": tableDataPapa[i][13],
          "Biller_ID": tableDataPapa[i][14],
          "Account_No": tableDataPapa[i][15]
        }
        let transaction = await UploadPaymentSCB(data);
        // console.log(tableDataPapa.length,i);
        // if(tableDataPapa.length === i+2){
          
        // }
    }
    getReport();
    
  }
  const indexOfLastRow = currentPagePapa * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tableDataPapa.slice(indexOfFirstRow, indexOfLastRow);

  // Pagination controls
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(async () => {
    getReport();
  }, [currentPage, perPage]);

  const getReport = async () => {
    setIsLoading(true);
    let date = {
      startDate: startDate,
      endDate: endDate
    }
    let transaction = await getPaymentSCB(date);
    if (transaction) {
      setIsLoading(false);
      setTableData(transaction.data);
      setransactionlength(transaction.data.length)
    }
    const dataArr = transaction?.data;
    if (dataArr?.length > 0) {
      let dataNew = [];
      dataArr.map((item, index) => {
        if (
          index + 1 >= currentPage * perPage - perPage + 1 &&
          index + 1 <= currentPage * perPage
        ) {
          dataNew.push(item);
        }
      });
      setTableData(dataNew);
    }
  };


  const selectDelete = [];
  const handleOnChange = (event) => {
    if (selectDelete.indexOf(event.transaction_id) > -1) {
      selectDelete.splice(selectDelete.indexOf(event.transaction_id), 1);
    } else {
      selectDelete.push(event.transaction_id);
    }
    //console.log("Selected : " + selectDelete.length);
  };

  const columns = [
    {
      title: "",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, index) => (
        <div>
          <input
            type="checkbox"
            id={`select-${data.payeeproxyid}`}
            name="checkDel"
            onClick={() => handleOnChange(index)}
          />
        </div>
      ),
    },
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "data-time",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.converdate_scb}
        </div>
      ),
    },
    {
      title: "payee-name",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.payee_name}
        </div>
      ),
    },
    {
      title: "payer-name",
      ellipsis: true,
      align: "payername",
      render: (data) => (
        <div>{data.payer_name || "-"}</div>
      ),
    },
    {
      title: "transactionid",
      ellipsis: true,
      render: (data) => <div>{data.transaction_id || "-"}</div>,
    }, {
      title: "amount",
      ellipsis: true,
      render: (data) => <div>{data.amount || "-"}</div>,
    },
    {
      title: "site_name",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "machine type",
      ellipsis: true,
      render: (data) => <div>{data.machine_group || "-"} </div>,
    },
    {
      title: "machine name",
      ellipsis: true,
      render: (data) => <div>{" " + data.machine_name || "-"} </div>,
    },
    {
      title: "Action type",
      ellipsis: true,
      render: (data) => <div>{data.type || "-"}</div>,
    },
    // {
    //   title: "ลูกค้า",
    //   ellipsis: true,
    //   className: "cs-pt",
    //   render: (data) => (
    //     <div
    //       className="cs-pt cl-g"
    //       onClick={() =>
    //         window.open(`/customer/list/${data.customer_id}`, "_blank")
    //       }
    //     >
    //       {data.customer_name || "-"}
    //     </div>
    //   ),
    // },
    // {
    //   title: "ช่องทาง",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) =>
    //     data.payment_type == "tw_pay" ? (
    //       <div
    //         onClick={() => getTransactionId(data?.transaction_id)}
    //         className="cs-pt cl-g"
    //       >
    //         TW Pay
    //       </div>
    //     ) : (
    //       <div>{paymentType(data.payment_type)}</div>
    //     ),
    // },
    // {
    //   title: "จำนวนเงิน",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => <div>{numberWithCommas(data.amount || 0, 2)}</div>,
    // },
    // {
    //   title: "สถานะ",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => <div>{data.status || "-"}</div>,
    // },
    // {
    //   title: "Action",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) =>
    //     menuList.includes("TRANSACMAN") && (
    //       <Tooltip placement="top" title="Delete">
    //         <div
    //           className="f-c-c d-b-t-n-s b-c-o-i"
    //           style={{ backgroundColor: "#ED2228" }}
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             setModalId(data.transaction_id);
    //             setModalTitle(data.transaction_id);
    //             setVisible(true);
    //           }}
    //         >
    //           <i className="fas fa-trash"></i>
    //         </div>
    //       </Tooltip>
    //     ),
    // },
  ];
  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let date = {
      startDate: startDate,
      endDate: endDate
    }

    let dataPayment = await getPaymentSCB(date);
    let csvData = [];
   // console.log(dataPayment)
    if (dataPayment) {

      (dataPayment.data || []).map((data, idx) => {
        let resultRow = {};
        resultRow["payeeproxyid"] = data.payee_proxy_id || "-";
        resultRow["payeeproxytype"] = data.payee_proxy_type || "-";
        resultRow["payeeaccountnumber"] = data.payee_account_number || "-";
        resultRow["payeename"] = data.payee_name || "-";
        resultRow["payeraccountnumber"] = data.payer_account_number || "-";
        resultRow["payeraccountname"] = data.payer_account_name || "-";
        resultRow["payername"] = data.payer_name || "-";
        resultRow["sendingbankcode"] = data.sending_bank_code || "-";
        resultRow["receivingbankcode"] = data.receiving_bank_code || "-";
        resultRow["amount"] = data.amount || "-";
        resultRow["transactionid"] = data.transaction_id || "-";
        resultRow["billpaymentref1"] = data.bill_payment_ref1 || "-";
        resultRow["billpaymentref2"] = data.bill_payment_ref2 || "-";
        resultRow["billpaymentref3"] = data.bill_payment_ref3 || "-";
        resultRow["currencycode"] = data.currency_code || "-";
        resultRow["channelcode"] = data.channel_code || "-";
        resultRow["transactiontype"] = data.transaction_type || "-";
        resultRow["transactiondateandtime"] = data.transaction_date_time || "-";
        resultRow["converdate_scb"] = data.converdate_scb || "-";
        resultRow["type"] = data.type || "-";
        resultRow["converdateSCB"] = data.converdateSCB || "-";
        resultRow["device_name"] = data.device_name || "-";
        resultRow["machine_name"] = data.machine_name || "-";
        resultRow["site_code"] = data.site_code || "-";
        resultRow["site_name"] = data.site_name || "-";
        resultRow["machine_group"] = data.machine_group || "-";
        csvData.push(resultRow);
      });
      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "Payment-SCB";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };
  const toCSVFileGroup = async () => {
    setWaitingForCSV(true);
    let date = {
      startDate: startDate,
      endDate: endDate
    }

    let dataPayment = await getPaymentSCB_groupsite(date);
    let csvData = [];
   // console.log(dataPayment)
    if (dataPayment) {

      (dataPayment.data || []).map((data, idx) => {
        let resultRow = {};
        resultRow["site_code"] = data.site_code || "-";
        resultRow["site_name"] = data.site_name || "-";
        resultRow["qrcode"] = data.qrcode || "-";

        csvData.push(resultRow);
      });
      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "Payment-SCB";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };
  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Payment SCB</div>
      {/* <input type="file" accept=".csv" onChange={handleFileUpload} /> */}


      <div className="h-d-t-g-w-d-d-s">
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
           // upload_file();
          }}
        
        >
          
          {/* {waitingForCSV ? <LoadingOutlined /> : "Upload CSV"} */}
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
          <RangePicker
            style={{ width: "100%" }}
            // format={(date) =>  moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div disabled style={{ pointerEvents: waitingForCSV ? "none" : null }}
          // onClick={() => toCSVFileGroup()} 
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5">
          {/* {waitingForCSV ? <LoadingOutlined /> : "Export CSV by Site"} */}
          
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            setCurrentPage(1);
            getReport();
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        {/* <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            //toCSVFileGroup()
            setCurrentPage(1)
            getReport();
          }}
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export Site"}
        </div> */}
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.transaction_id}
        dataSource={(tableData) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />
      {/* {tableDataPapa.length > 0 && (
        <div>
          <div style={{ overflowX: 'auto', marginTop: '20px' }}>
            <table border="1" cellPadding="5" style={{ width: '100%', minWidth: '600px' }}>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentRows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {[...Array(Math.ceil(tableDataPapa.length / rowsPerPage)).keys()].map((number) => (
              <button
                key={number}
                onClick={() => paginate(number + 1)}
                style={{
                  margin: '0 5px',
                  padding: '8px 12px',
                  cursor: 'pointer',
                  backgroundColor: currentPage === number + 1 ? '#007bff' : '#ccc',
                  color: currentPage === number + 1 ? '#fff' : '#000',
                  border: 'none',
                  borderRadius: '4px'
                }}
              >
                {number + 1}
              </button>
            ))}
          </div>
        </div>
      )} */}
      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={
            transactionlength
          }
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>

    </div>
  );
};

export default PaymentSCB;
