import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Table, Pagination, Avatar, Modal, Tooltip, Input, InputNumber, Checkbox, Select } from 'antd';
import './Customer.css'
import XLSX from "xlsx";
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { numberWithCommas, openNotification } from '../../utils/function'
import {
  apiCTMGetCustomerById,
  apiCTMGetCustomerHistoryById,
  apiCTMUpdateCreditByCustomerId,
  apiCTMEditCustomerPasswordById,

  apiCTMRefundHistoryByCustomerId,
  apiCTMEditCustomerById,
  apiAxiostw_CTMEditCustomerPasswordById,
  apiAxiostw_DeleteCTMCustomer
} from '../../services/apis/customer'
import { customerByid, creditsHistoryByid, addCreditHistory } from '../../services/apis/mobileApp'
import { dateFormat } from 'highcharts';
const { Option } = Select;

const CustomerView = () => {
  const { customerId } = useParams();
  const menuList = (localStorage.getItem('list') || '').split(',')
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [tableData, setTableData] = useState(undefined)
  const [customer, setCustomer] = useState(undefined)
  const [waitingForCSV, setWaitingForCSV] = useState(false)

  const [credit, setCredit] = useState({ credit_type: 'Deposit', credit_value: undefined, action: 1 })
  const [visibleCredit, setVisibleCredit] = useState(false)
  const [isLoadingConfirmCredit, setIsLoadingConfirmCredit] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [isLoadingConfirmPassword, setIsLoadingConfirmPassword] = useState(false)

  const [visibleRefund, setVisibleRefund] = useState(false)
  const [modalIdRefund, setModalIdRefund] = useState(undefined)
  const [isLoadingConfirmRefund, setIsLoadingConfirmRefund] = useState(false)

  const [isAlertCredit, setIsAlertCredit] = useState(false)

  const creditChoices = [
    { key: 'Deposit', action: 0, name: 'เติมเครดิต' },
    { key: 'Withdraw', action: 1, name: 'ถอนเครดิต' }
  ]

  useEffect(() => {
    if (customerId) getData()
  }, [customerId])

  useEffect(() => {
    getDataTable()
  }, [currentPage, perPage])

  const getData = async () => {
    let data = await customerByid(customerId)
    console.log(data.data)
    if (data && data.status) setCustomer(data.data)
  }

  const getDataTable = async () => {
    setIsLoadingTable(true)
    let data = await creditsHistoryByid(customerId, currentPage)
    console.log(data)
    console.log(data)
    if (data.status) {
      setTableData(data)
      setIsLoadingTable(false)
    }
  }

  const updateCustomerRole = async role => {
    let data = { ...customer }
    data.customer_role = role

    let newData = await apiCTMEditCustomerById(data)
    if (newData && newData.status) {
      getData()
      openNotification('success', 'Success !', 'Update customer role successful.')
    }
    else {
      getData()
      openNotification('error', 'Failed !', 'Failed to update customer role.')
    }
  }
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // เดือนเริ่มที่ 0
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const updateCredit = async () => {

    if (!customerId) {
      openNotification('error', 'Failed !', `Failed to ${(credit.credit_type).toLowerCase()} credit.`)
      return
    }

    if (isNaN(credit.credit_value)) setIsAlertCredit(true)
    else {
      //setIsLoadingConfirmCredit(true)
      if (credit.credit_type == 'Deposit') {
        let data = {
          "customer_id": customerId, //_id 66de5d9b401101b3d8f89664  // ไม่ต้องส่งมาแล้ว
          "value": credit.credit_value, //เงินที่ใช้ ห้ามใส่ ติดลบ
          "action": 0, // 0=เพิ่มเงิน,1=ลบเงิน ใช้งาน ,2=รอตรวจสอบ, -1=คืนเงิน
          "payment_evidence": "transationId", // transationId ถ้าเติมเงิน , admin  ถ้าใช้งาน ส่งว่างหรือคำว่าUse
          "lastupdate": getCurrentDateTime(), //2024-09-09 02:29:47
          "machine_no": "", // เลข mac 
          "tr_time": "admin",//type เติมเงิน SCB, ใช้งาน ผ่าน qr_code , mobile , coin
          "tr_date": "admin", //ว่าง
          "promotion": "admin", //ว่าง
          "mch_order_no": ""
        }
        let action = await addCreditHistory(data)
        if (action && action.status) {
          getData()
          getDataTable()
          openNotification('success', 'Success ! credit successful.')
          setVisibleCredit(false)
          setIsLoadingConfirmCredit(false)
        } else {
          openNotification('error', 'Failed !', `Failed to credit.`)
          setIsLoadingConfirmCredit(false)
        }
      } else if (credit.credit_type == 'Withdraw') {
        let data = {
          "customer_id": customerId, //_id 66de5d9b401101b3d8f89664  // ไม่ต้องส่งมาแล้ว
          "value": credit.credit_value, //เงินที่ใช้ ห้ามใส่ ติดลบ
          "action": 1, // 0=เพิ่มเงิน,1=ลบเงิน ใช้งาน ,2=รอตรวจสอบ, -1=คืนเงิน
          "payment_evidence": "transationId", // transationId ถ้าเติมเงิน , admin  ถ้าใช้งาน ส่งว่างหรือคำว่าUse
          "lastupdate": getCurrentDateTime(), //2024-09-09 02:29:47
          "machine_no": "", // เลข mac 
          "tr_time": "admin",//type เติมเงิน SCB, ใช้งาน ผ่าน qr_code , mobile , coin
          "tr_date": "admin", //ว่าง
          "promotion": "admin", //ว่าง
          "mch_order_no": ""
        }
        let action = await addCreditHistory(data)
        if (action && action.status) {
          getData()
          getDataTable()
          openNotification('success', 'Success !', ` credit successful.`)
          setVisibleCredit(false)
          setIsLoadingConfirmCredit(false)
        } else {
          openNotification('error', 'Failed !', `Failed to  credit.`)
          setIsLoadingConfirmCredit(false)
        }
      }
    }
  }

  const resetPassword = async () => {
    if (!newPassword) return

    setIsLoadingConfirmPassword(true)

    let data = {
      customer_id: Number(customerId),
      password: newPassword
    }

    let action = await apiCTMEditCustomerPasswordById(data)
    if (action && action.status) {
      getData()
      openNotification('success', 'Success !', 'Reset password successful.')
      setVisiblePassword(false)
      setIsLoadingConfirmPassword(false)
    } else {
      openNotification('error', 'Failed !', 'Failed to reset password.')
      setIsLoadingConfirmPassword(false)
    }
  }

  const refundData = async () => {
    if (!modalIdRefund) {
      openNotification('error', 'Failed !', 'Failed to refund.')
      return
    }

    setIsLoadingConfirmRefund(true)

    let data = {
      customer_id: Number(customerId),
      history_id: modalIdRefund
    }
    let action = await apiCTMRefundHistoryByCustomerId(data)
    if (action && action.status) {
      getData()
      getDataTable()
      openNotification('success', 'Success !', 'Refund successful.')
      setVisibleRefund(false)
      setIsLoadingConfirmRefund(false)
    } else {
      openNotification('error', 'Failed !', 'Failed to refund.')
      setIsLoadingConfirmRefund(false)
    }
  }

  const columns = [
    {
      title: '#',
      ellipsis: true,
      align: 'center',
      width: '5%',
      render: (data, record, index) => <div>{(index + 1) + ((currentPage - 1) * perPage)}</div>
    },
    {
      title: 'Date',
      ellipsis: true,
      align: 'center',
      render: (data) => <div>{data.eventdate ? moment(data.eventdate).add(543, 'years').format('D MMM YYYY HH:mm:ss') : '-'}</div>
    },
    {
      title: 'Site',
      ellipsis: true,
      render: (data) =>
        <div>
          {
            data.action === 0 ? (
              <div>
                <div> -</div>
              </div>
            ) : data.action === 1 ? (
              <div
              > {data.machine_info[0].site_name || '-'}</div>
            ) : (
              'Unknown Status' // For other status values
            )}
        </div>
    },
    {
      title: 'Machine',
      ellipsis: true,
      render: (data) => 
        <div>
      {
        data.action === 0 ? (
          <div>
            <div> - </div>
          </div>
        ) : data.action === 1 ? (
          <div
          > {data.machine_info[0].machine_group +" "+data.machine_info[0].machine_name || '-'} </div>
        ) : (
          'Unknown Status' // For other status values
        )}
    </div>
    },
    {
      title: 'Amount',
      ellipsis: true,
      align: 'center',
      render: (data) =>
        <div>
          {
            data.action === 0 ? (
              <div>
                <div> เติมเงิน + {data.value.$numberDecimal || '-'}</div>
              </div>
            ) : data.action === 1 ? (
              <div
              > <div> - {data.value.$numberDecimal || '-'}</div></div>
            ) : (
              'Unknown Status' // For other status values
            )}
        </div>

    }, {
      title: 'Promotion',
      ellipsis: true,
      align: 'center',
      render: (data) =>
        <div>
          {<div> {data.promotion || '-'}</div>}
        </div>

    }
    // {
    //   title: 'Action',
    //   ellipsis: true,
    //   align: 'center',
    //   fixed: 'right',
    //   render: (data) => <Tooltip placement="top" title="Refund">
    //     <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#ED2228' }}
    //       onClick={(e) => {
    //         e.stopPropagation()
    //         setModalIdRefund(data.history_id)
    //         setVisibleRefund(true)
    //       }}
    //     ><i className="fas fa-coins"></i></div>
    //   </Tooltip>,
    // },
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true)
    let data = await apiCTMGetCustomerHistoryById(true, '', '', customerId)
    let csvData = [];

    if (data && data.status) {
      (data.result.data_list || []).map((data, idx) => {
        let resultRow = {}
        resultRow['#'] = idx + 1
        resultRow['Date'] = data.date || '-'
        resultRow['Site'] = data.site_name || '-'
        resultRow['Machine'] = data.machine_name || '-'
        resultRow['Amount'] = data.amount || 0
        csvData.push(resultRow);
      })

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = `CUSTOMER_${customerId}_HISTORY`
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false)
    }
  }

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Customer - View</div>

      {(menuList.includes('CUSTOMERMAN') || menuList.includes('CUSTOMERCREDIT')) && <div
        className={`customer-view-header-grid customer-view-header-grid-${menuList.includes('CUSTOMERMAN') && menuList.includes('CUSTOMERCREDIT')}`}
      >
        {menuList.includes('CUSTOMERMAN') && <div>
          <Select style={{ width: '100%' }}
            placeholder="Role"
            value={customer && customer.customer_role || undefined}
            onChange={value => updateCustomerRole(value)}
            disabled  >
            <Option value="NORMAL">Normal</Option>
            <Option value="ADMIN">Admin</Option>
            <Option value="SALE">Sale</Option>
            <Option value="DEVELOP">Develop</Option>
            <Option value="OPERATION">Operation</Option>
          </Select>
        </div>}
        {menuList.includes('CUSTOMERCREDIT') &&
          <div className="f-c-c d-b-t-n-s d-bg-c-t"
            onClick={() => {
              setCredit({ credit_type: 'Deposit', credit_value: undefined })
              setIsAlertCredit(false)
              setVisibleCredit(true)
            }}>Credit</div>}
      </div>}

      <div className="ovf-sc">
        <div className="customer-view-container-grid">
          <div className="f-c-c customer-view-profile-image">
            <Avatar shape="square" src={customer && customer.customer_img || '/assets/image/default/user_image.png'} />
          </div>
          <div className="customer-view-info-container">
            <div>
              <div className="customer-view-info">
                <div>Name</div>
                <div>{customer && customer.name || '-'}</div>
                <div>Tel.</div>
                <div>{customer && customer.tel || '-'}</div>
                <div>E-mail</div>
                <div>{customer && customer.email || '-'}</div>
                <div>apple</div>
                <div>{customer && customer.apple_id || '-'}</div>
                <div>google</div>
                <div>{customer && customer.google_id || '-'}</div>
                <div>line</div>
                <div>{customer && customer.line_id || '-'}</div>
                <div>Credit</div>
                <div>{numberWithCommas(customer && customer.credit_remaining || 0, 2)}</div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="dash-chart-box-container">
        <div className="dash-space-between">
          <div className="h-d-t-g">
            <div className="f-c-c"><i className="bi bi-clock-history" style={{ lineHeight: '0' }}></i></div>
            <div className="f-s-c">History</div>
            {/* <div className="f-c-c d-b-t-n-s d-bg-c-t h-32 table-export-btn-width table-export-btn-responsive" style={{ pointerEvents: waitingForCSV ? 'none' : null }} onClick={() => toCSVFile()}>
              {waitingForCSV ? <LoadingOutlined /> : 'Export CSV'}
            </div> */}
          </div>

          <div className="dash-table-container">
            <Table bordered
              size="small"
              loading={isLoadingTable}
              rowKey={record => record.history_id}
              dataSource={tableData && tableData.credits || []}
              columns={columns}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>

          <div className="pgnt-st">
            <Pagination
              size="small"
              current={currentPage}
              onChange={page => setCurrentPage(page)}
              total={tableData && tableData.totalCount}
              pageSize={perPage}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>

      <Modal
        title=""
        visible={visibleCredit}
        closable={false}
        footer={false}
        centered
      >
        <div className="customer-view-credit-grid">
          {creditChoices.map((choice, idx) => <div key={idx} className="f-s-c antd-chb-fz">
            <Checkbox
              checked={credit.credit_type === choice.key}
              onChange={() => setCredit({ ...credit, credit_type: choice.key, action: choice.action })}
            >{choice.name}</Checkbox>
          </div>
          )}
        </div>

        <div className="mg-t-10">
          <InputNumber min={0}
            step={0.01}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%', borderColor: isAlertCredit ? '#EC1C24' : null }}
            placeholder="จำนวนเงิน"
            value={credit.credit_value}
            onChange={value => {
              setIsAlertCredit(false)
              setCredit({ ...credit, credit_value: Math.round(value * 100) / 100 || 0 })
            }}
          />
        </div>

        <div className="m-b-y-n-g">
          <div className="f-c-c d-b-t-n-s b-c-o-i" style={{ backgroundColor: '#cccccc' }} onClick={() => setVisibleCredit(false)}>Cancel</div>
          <div className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: '#3f4d67', pointerEvents: isLoadingConfirmCredit ? 'none' : null }}
            onClick={() => updateCredit()}
          >{isLoadingConfirmCredit ? <LoadingOutlined /> : 'Confirm'}</div>
        </div>
      </Modal>



    </div>
  )
}

export default CustomerView
