import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Select,Input, Checkbox } from "antd";
import "./Modal.css";
import { LoadingOutlined } from "@ant-design/icons";

import { numberWithCommas, openNotification } from "../../utils/function";
import {
  changeModalMachineAction,
  changeReloadStatus,
} from "../../redux/machine";

import { apiCTMGetAllCustomer } from "../../services/apis/customer";
import { apiMCHActionMachine, apistartMachine_admin } from "../../services/apis/machine";
import { ADD_START_DEVICES } from "../../resolvers/Mutation";
import { useMutation } from "@apollo/client";

const { Option } = Select;

const ModalMachineActionV2 = (props) => {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const [customers, setCustomers] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false)
  const [modalActionData, setModalActionData] = useState(undefined);
  console.log(modalActionData)
  const [initPrice, setInitPrice] = useState(0);
  const [isAlertCustomer, setIsAlertCustomer] = useState(false);
  const [calTimeDryer, setCalTimeDryer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const user_name = (localStorage.getItem("user_name") || "").toUpperCase();

  // console.log("modalActionData---🙂🙂", modalActionData);
  const modes = {
    เครื่องซักผ้า: [
      {
        key: 1,
        name: "น้ำเย็น",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม", "เครื่องอุตสาหกรรมจีน"],
      },
      {
        key: 2,
        name: "น้ำอุ่น",
        value: 10,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม", "เครื่องอุตสาหกรรมจีน"],
      },
      {
        key: 3,
        name: "น้ำร้อน",
        value: 20,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม", "เครื่องอุตสาหกรรมจีน"],
      },
      {
        key: 4,
        name: "ผ้าห่มน้ำเย็น",
        value: 0,
        filter: ["เครื่องอุตสาหกรรม"],
      },
      {
        key: 5,
        name: "ถนอมผ้าน้ำอุ่น",
        value: 10,
        filter: ["เครื่องอุตสาหกรรม"],
      },
      {
        key: 6,
        name: "ถนอมผ้าน้ำเย็น",
        value: 0,
        filter: ["เครื่องอุตสาหกรรม"],
      },
    ],
    เครื่องอบผ้า: [
      {
        key: 1,
        name: "ร้อนปกติ",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม"],
      },
      {
        key: 2,
        name: "ร้อนปานกลาง",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม"],
      },
      {
        key: 3,
        name: "ร้อนสูง",
        value: 0,
        filter: ["เครื่องพานิช", "LG commercial", "เครื่องอุตสาหกรรม"],
      },
      { key: 4, name: "ผ้าบอบบาง", value: 0, filter: ["เครื่องอุตสาหกรรม"] },
    ],
  };

  const options = {
    เครื่องซักผ้า: [
      {
        key: 1,
        name: "เพิ่มรอบล้าง",
        value: 10,
        filter: ["เครื่องพานิช", "เครื่องอุตสาหกรรม"],
      },
      { key: 2, name: "เพิ่มรอบซัก", value: 10, filter: ["เครื่องอุตสาหกรรม"] },
    ],
  };


  useEffect(() => {
    if (machine.isModalMachineAction) {
      let initCalTimeDryer = 0;
      let propsData = props.modalActionData;

      let newData = {
        _action_by: "coin",
        _mode: 1,
        _customer_id: undefined,
        _customer_name: undefined,
        _options: [],
      };

      if (propsData?.machine_group?.includes("เครื่องอบ")) {
        switch (propsData?.machine_sub_group) {
          case "เครื่องพานิช":
            initCalTimeDryer = 10;
            break;
          case "เครื่องอุตสาหกรรม":
            initCalTimeDryer = 6;
            break;
          default:
            initCalTimeDryer =
              (Number(parseInt(propsData?.time_default) || 0) * 10) /
              (Number(parseInt(propsData?.price_default) || 0) * 60);
            break;
        }
      }

      setCalTimeDryer(
        initCalTimeDryer == "Infinity" ? 0 : initCalTimeDryer ?? 0
      );
      setInitPrice(parseInt(propsData?.price_default));
      setModalActionData({ ...newData, ...propsData });
      setIsAlertCustomer(false);
    }
  }, [machine.isModalMachineAction]);

  // useEffect(() => {
  //   const getStaticDropdown = async () => {
  //     let customer = await apiCTMGetAllCustomer("");
  //     if (customer && customer.status) setCustomers(customer.result || []);
  //   };
  //   getStaticDropdown();
  //   dispatch(changeModalMachineAction(false));
  // }, []);

  const displayMode = () => {
    switch (modalActionData && modalActionData.machine_sub_group) {
      case "เครื่องพานิช":
      case "เครื่องอุตสาหกรรม":
      case "เครื่องอุตสาหกรรมจีน":
      case "LG commercial":
        return true;

      default:
        return false;
    }
  };

  const displayOptions = () => {
    if (!modalActionData?.machine_group?.includes("เครื่องซัก")) return false;

    switch (modalActionData && modalActionData.machine_sub_group) {
      case "เครื่องพานิช":
      case "เครื่องอุตสาหกรรม":
        return true;

      default:
        return false;
    }
  };

  const displayTimeChnage = () => {
    if (modalActionData?.machine_group?.includes("เครื่องซัก")) return false;
    return true;
  };

  useEffect(() => {
    if (modalActionData) {
      if (!modalActionData?.machine_group?.includes("เครื่องซัก")) return;

      let modePrice = 0;
      let mode = (
        modes[modalActionData && modalActionData.machine_group] || []
      ).find((list) => list.key === modalActionData._mode);
      if (mode) modePrice = mode.value;

      let optionPrice = 0;
      (options[modalActionData && modalActionData.machine_group] || []).map(
        (option) => {
          if (modalActionData._options.includes(option.key))
            optionPrice += option.value;
        }
      );

      setModalActionData({
        ...modalActionData,
        price_default: initPrice + modePrice + optionPrice,
      });
    }
  }, [
    modalActionData && modalActionData._mode,
    modalActionData &&
    modalActionData._options &&
    modalActionData._options.length,
  ]);

  const actionData = async () => {
    if (
      modalActionData &&
      modalActionData._action_by === "mobile" &&
      !(modalActionData && modalActionData._customer_id)
    )
      setIsAlertCustomer(true);
    else {
    
      if (modalActionData) {
        let data = {
          "site_code": modalActionData?.siteCode,
          "device_name": modalActionData?.deviceName,
          "customer_id": "1",
          "customer_name":  "Tw admin : "+ user_name||"",
          "action_price":modalActionData?.price_default + "",
          "action_via": "mobile",
          "promotion_id": "",
          "mode": modalActionData._mode + "",
          "option": "",
          "price_default": modalActionData?.price_default + "",
          "action": "1",
          "action_time": modalActionData?.time_default+ ""
        }
        let resData = await apistartMachine_admin(data)
        if (resData.startDevice.status) {
          dispatch(changeReloadStatus())
          dispatch(changeModalMachineAction(false))
          setIsLoadingConfirm(false)
          openNotification('success', 'Success !', resData.startDevice.message && `Success with status : ${ resData.startDevice.message}.` || 'Action machine successful.')
        } else {
          setIsLoadingConfirm(false)
          openNotification('error', 'Failed !', resData.startDevice.message && `Failed with status : ${ resData.startDevice.message}.` || 'Failed to action machine.')
        }
        // setIsLoading(true);
        // addStartDevice({
        //   variables: {
        //     deviceName: modalActionData?.deviceName,
        //     // customer_id: modalActionData?._customer_id
        //     //   ? modalActionData?._customer_id + ""
        //     //   : "",
        //     customer_id: 1 + "",
        //     customer_name: modalActionData?._customer_name + "",
        //     action_price: modalActionData?.price_default + "",
        //     action_via: modalActionData._action_by,
        //     promotionId: modalActionData?.promotionId || "",
        //     mode: modalActionData._mode + "",
        //     option: modalActionData._options + "",
        //     price_default: modalActionData?.price_default + "",
        //   },
        // });
      }
    }
  };

  return (
    <div>
      <Modal
        title=""
        visible={machine?.isModalMachineAction}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-t-q">
         เครื่องหมายเลข : {(modalActionData && modalActionData.machine_name) || ""}
        </div>

        <div className="t-m-c-i-p-g mg-t-10">
          {displayMode() && <div className="f-s-c">Mode</div>}
          {displayMode() && (
            <div>
              {(modes[modalActionData && modalActionData.machine_group] || [])
                .filter((list) =>
                  list.filter.includes(
                    modalActionData && modalActionData.machine_sub_group
                  )
                )
                .map((mode, idx) => (

                  <div key={idx} className="f-s-c">
                    <Checkbox
                      checked={
                        modalActionData && modalActionData._mode === mode.key
                      }
                      onChange={(e) =>
                        setModalActionData({
                          ...modalActionData,
                          _mode: mode.key,
                        })
                      }
                    >
                      {mode.name}
                    </Checkbox>
                  </div>
                ))}
            </div>
          )}
          {displayOptions() && <div className="f-s-c">Options Wash</div>}
          {displayOptions() && (
            <div>
              {(options[modalActionData && modalActionData.machine_group] || [])
                .filter((list) =>
                  list.filter.includes(
                    modalActionData && modalActionData.machine_sub_group
                  )
                )
                .map((option, idx) => (
                  <div key={idx} className="f-s-c">
                    <Checkbox
                      checked={
                        modalActionData &&
                        modalActionData._options.includes(option.key)
                      }
                      onChange={(e) => {
                        let newData = { ...modalActionData };

                        if (e.target.checked) newData._options.push(option.key);
                        else
                          newData._options = modalActionData._options.filter(
                            (list) => list !== option.key
                          );

                        setModalActionData(newData);
                      }}
                    >
                      {option.name}
                    </Checkbox>
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="mg-t-20 t-al-c" style={{ fontSize: "20px" }}>
          <div>
            เวลา{" "}
            {numberWithCommas((modalActionData?.time_default || 0) / 60, 0)}{" "}
            นาที
          </div>
          <div>
            ราคา {numberWithCommas(modalActionData?.price_default || 0, 2)} บาท
          </div>
        </div>

        {displayTimeChnage() && (
          <div className="machine-action-add-time-grid">
            <div
              className="f-c-c d-b-t-n-s b-c-o-i"
              style={
                ((modalActionData && modalActionData.time_default) || 0) / 60 <
                  calTimeDryer ||
                  ((modalActionData && modalActionData?.price_default) || 0) < 10 // time and price
                  ? { backgroundColor: "#cccccc", pointerEvents: "none" }
                  : { backgroundColor: "#3f4d67" }
              }
              onClick={() =>
                parseInt(modalActionData.time_default) - calTimeDryer * 60 >=
                  0 && parseInt(modalActionData?.price_default) - 10 >= 0
                  ? setModalActionData({
                    ...modalActionData,
                    time_default:
                      parseInt(modalActionData.time_default) -
                      calTimeDryer * 60,
                    price_default:
                      parseInt(modalActionData?.price_default) - 10,
                  })
                  : null
              }
            >
              -
            </div>
            <div className="f-c-c">เพิ่ม / ลดเวลา</div>
            <div
              className="f-c-c d-b-t-n-s b-c-o-i"
              style={{ backgroundColor: "#3f4d67" }}
              onClick={() =>
                setModalActionData({
                  ...modalActionData,
                  time_default:
                    parseInt(modalActionData.time_default) + calTimeDryer * 60,
                  price_default: parseInt(modalActionData?.price_default) + 10,
                })
              }
            >
              +
            </div>
          </div>
        )}

        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => dispatch(changeModalMachineAction(false))}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoading ? "none" : null,
            }}
            onClick={() => actionData()}
          >
            {isLoading ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalMachineActionV2;
